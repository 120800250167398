import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import InputLabel from "../Users/InputLabel";
import { createPortal } from "react-dom";
import { useIsOpen, useUpdatePosition, useOutsideClick } from "../Inputs/hooks";

const availableArrows = {
  dark: "/images/dicio/arrow_down.webp",
  white: "/images/icons/arrow_down_white.png",
};

const OptionContainer = ({
  targetRef,
  handleChange,
  value,
  maxTime,
  minTime,
  position,
}) => {
  return createPortal(
    <div ref={targetRef} className="input_option_time shadow" style={position}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MultiSectionDigitalClock
          defaultValue={dayjs("HH:mm")}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
          onChange={handleChange}
          value={dayjs(value, "HH:mm")}
          views={["hours", "minutes"]}
          {...{
            maxTime,
            minTime,
          }}
        />
      </LocalizationProvider>
    </div>,
    document.getElementById("root")
  );
};

const useSelect = ({ handleSetValue, id }) => {
  const { isOpen, setIsOpen, handleClick } = useIsOpen();
  const { position, containerRef, updatePosition } = useUpdatePosition();
  const { targetRef } = useOutsideClick({ containerRef, setIsOpen });

  const handleChange = (time) => {
    handleSetValue({ field: id, value: time.format("HH:mm") });
  };

  useEffect(() => {
    updatePosition();
  }, [isOpen, updatePosition]);

  return {
    isOpen,
    handleChange,
    handleClick,
    containerRef,
    targetRef,
    position,
  };
};

export default function TimeInput({
  error,
  value,
  handleSetValue,
  id,
  label,
  arrow = "dark",
  arrowWidth = 10,
  arrowPosition = {},
  max_width,
  icon = null,
  rounded,
  maxTime = null,
  minTime = null,
}) {
  const {
    isOpen,
    handleChange,
    handleClick,
    containerRef,
    targetRef,
    position,
  } = useSelect({
    handleSetValue,
    id,
    value,
  });
  const inputError = !!(value !== "" && error);
  const inputValid = !!(value !== "" && !error);
  const labelProps = {
    focused: value !== "",
    inputError,
    inputValid,
    rounded,
  };
  return (
    <div
      ref={containerRef}
      className={`container-fluid p-0 mt-3  position-relative`}
      style={{ maxWidth: `${max_width}px`, marginLeft: "0", marginLeft: "0" }}
    >
      {label !== "" && <InputLabel {...labelProps}>{label}</InputLabel>}
      <button
        data-testid="time-button"
        className={`input_select  d-flex overflow-x-hidden justify-content-start ${
          rounded ? "input_select_small rounded-pill" : "input_select"
        }
        border ${
          inputError
            ? `border-danger`
            : rounded
            ? "border_for_small_inputs"
            : `border-black`
        } `}
        onClick={handleClick}
      >
        <span
          className="label_value_select"
          style={{
            maxWidth: `${max_width - 20}px`,
            color: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
            lineHeight: "inherit",
          }}
        >
          {value ?? ""}
        </span>
      </button>
      {icon || (
        <img
          src={availableArrows[arrow]}
          width={arrowWidth}
          height={"auto"}
          className={`pe-none ${
            rounded ? "input_select_icon_small" : "input_select_icon"
          } ${
            isOpen
              ? rounded
                ? "input_select_icon_open_small"
                : "input_select_icon_open"
              : ""
          }`}
          alt="Select icon arrow"
          style={{
            cursor: "pointer",
            width: `${arrowWidth}px`,
            ...arrowPosition,
          }}
        />
      )}
      {isOpen && (
        <OptionContainer
          {...{
            targetRef,
            position,
            handleChange,
            rounded,
            value,
            maxTime,
            minTime,
          }}
        />
      )}
    </div>
  );
}
