import React, { useState, useReducer, useEffect, useContext } from "react";
import Input from "../Inputs/Input";
import DatePickerInput from "../Inputs/DatePickerInput";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import dayjs from "dayjs";
import InputPhone from "../Inputs/InputPhone";
import SelectorAvatar from "../RegistroUsuario/SelectorAvatar";
import InputToggle from "../Inputs/InputToggle";
import AssignRolesForm from "./AssignRolesForm";
import { REGEXInputNombre, TIME_ALERT } from "../../services/data";

import {
  getCatalogoAreas,
  getCatalogoPosicion,
  getAllRolesService,
  getInfoUser,
  editUserService,
  addRolUserService,
  deleteRolUserService,
} from "../../services/api";
import ToSkeleton from "../Skeleton/ToSkeleton";
import { UserContext } from "../Users/Body";
import Select from "./Select";

const useUserContext = () => {
  try {
    const context = useContext(UserContext);
    if (!context) {
      throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
  } catch (error) {
    return {
      handleRefreshData: () => {
        console.warn(
          "handleRefreshData is not available because UserProvider is missing"
        );
      },
    };
  }
};

const validateObject = (obj) => {
  if (obj.phone === "" || obj.phone?.length < 10) return false;
  return Object.values(obj).reduce((acc, value) => acc && value !== "", true);
};

const INITIAL_VALUES = {
  id: "",
  name: "",
  paternal_surname: "",
  maternal_surname: "",
  profile_image: "",
  extension_file: "",
  birdth_date: "",
  phone: "",
  email: "",
  roleIds: [],
  area: "",
  position: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      if (action.field === "phone") {
        if (/^\d*$/.test(action.value)) {
          return {
            ...state,
            [action.field]: action.value,
          };
        }
        return {
          ...state,
        };
      }
      if (action.field === "roleIds") {
        const { roleIds } = state;
        if (roleIds.includes(action.value)) {
          return {
            ...state,
            roleIds: roleIds.filter((item) => item !== action.value),
          };
        } else {
          return {
            ...state,
            roleIds: [...roleIds, action.value],
          };
        }
      }
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "SET_FIELDS":
      return {
        ...action.value,
      };
    default:
      return state;
  }
};

const handleEditUser = async ({
  values,
  loading,
  error,
  error_message,
  success,
  success_message,
  services,
  handleRefreshData,
  handleModal,
  permissionsToDelete,
  permissionsToAdd,
}) => {
  try {
    loading();
    const { id, ...restValues } = values;
    if (Object.keys(restValues).length > 0) {
      const response = await services.editUserService(values);
      if (!response.success) {
        loading();
        error();
        error_message(response.message);
        setTimeout(() => {
          error();
          error_message("");
        }, TIME_ALERT);
        console.error("Error request");
        return;
      }
    }
    if (permissionsToDelete.length > 0) {
      const responseDeleteRoles = await services.deleteRolUserService({
        userId: values.id,
        roleIds: permissionsToDelete,
      });
      if (!responseDeleteRoles.success) {
        loading();
        error();
        error_message(responseDeleteRoles.message);
        setTimeout(() => {
          error();
          error_message("");
        }, TIME_ALERT);
        console.error("Error request");
        return;
      }
    }
    if (permissionsToAdd.length > 0) {
      const responseAddRoles = await services.addRolUserService({
        userId: values.id,
        roleIds: permissionsToAdd,
      });
      if (!responseAddRoles.success) {
        loading();
        error();
        error_message(responseAddRoles.message);
        setTimeout(() => {
          error();
          error_message("");
        }, TIME_ALERT);
        console.error("Error request");
        return;
      }
    }
    loading();
    success();
    success_message("Usuario actualizado con éxito");
    setTimeout(() => {
      success();
      success_message("");
      if (handleRefreshData) handleRefreshData();
      if (handleModal) handleModal();
    }, TIME_ALERT);
  } catch (err) {
    loading();
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const errorReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "REMOVE_FIELD":
      const { [action.field]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
};

const useEditForm = ({ idUser, handleModal, type }) => {
  const [errors, dispatchErrors] = useReducer(errorReducer, {});
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [dbValues, setDbValues] = useState({});
  const [localUserID, setLocalUserID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingToEdit, setLoadingToEdit] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [finished, setFinished] = useState(true);
  const [rolCatalog, setRolCatalog] = useState([]);
  const [areaCatalog, setAreaCatalog] = useState([]);
  const [positionCatalog, setPositionCatalog] = useState([]);
  const [positionSelectedCatalog, setPositionSelectedCatalog] = useState([]);
  const [userData, setUserData] = useState(null);
  const { handleRefreshData } = useUserContext();
  const [isValid, setIsValid] = useState(false);

  const getUserInfo = async (userInfo) => {
    try {
      if (!navigator.onLine) {
        setError(true);
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      let res = await getInfoUser(userInfo);
      let { data, isSucceed } = await res.data;
      if (!isSucceed) {
        setError(true);
        setErrorMessage("Error al obtener datos del usurio");
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      const responseValues = {
        id: data.user.id,
        name: data.user.nombre,
        paternal_surname: data.user.apellidoPaterno,
        maternal_surname: data.user.apellidoMaterno,
        profile_image: data.user.imagenPerfil,
        birdth_date: data.user.fechaNacimento
          ? dayjs(
              data.user.fechaNacimento.split("T").length > 0
                ? data.user.fechaNacimento.split("T")[0]
                : data.user.fechaNacimento
            ).format("YYYY-MM-DD")
          : "",
        phone: data.user.telefono,
        email: data.user.email,
        roleIds: data.roles.map(({ id }) => id),
        position: data.user.puestoId,
        area: data.user.areaId,
      };
      setDbValues(responseValues);
      dispatch({
        type: "SET_FIELDS",
        value: responseValues,
      });
      setUserData(data);
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage("Error al obtener datos del usurio");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      return;
    }
  };

  const obtenerCatalogoAreas = async () => {
    try {
      let { data } = await getCatalogoAreas();
      if (data.isSucceed) setAreaCatalog(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const obtenerCatalogoPosicion = async () => {
    try {
      let { data } = await getCatalogoPosicion();
      if (data.isSucceed) setPositionCatalog(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRolCatalog = async () => {
    try {
      const response = await getAllRolesService();
      if (!response.success) {
        console.error(response.message);
        return;
      }
      setRolCatalog(response.data);
      return;
    } catch (error) {
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  const getAllCatalogs = async () => {
    await obtenerCatalogoAreas();
    await obtenerCatalogoPosicion();
    await handleRolCatalog();
  };

  const resetFieldValue = (field, value) => {
    dispatch({ type: "RESET_FIELD", field, value });
  };

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = () => {
    setError((prevState) => !prevState);
  };

  const handleErrorMessage = (msj) => {
    setErrorMessage(msj);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = (msj) => {
    setSuccessMessage(msj);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleCheckbox = (e) => {
    const { value, name } = e.target;
    setFieldValue("roleIds", name);
  };

  const handleManualChange = (values) => {
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleAvatarChange = (values) => {
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleSelectChange = (val) => {
    const { name, value } = val;
    if (name === "area" && value?.area && values?.area !== value)
      setFieldValue("position", "");
    setFieldValue(name, value);
  };

  const handleDatePickerChange = (newValue) => {
    const { name, value } = newValue;
    const valueSet =
      dayjs(value).format("YYYY-MM-DD") !== "Invalid Date"
        ? dayjs(value).format("YYYY-MM-DD")
        : "";
    setFieldValue(name, valueSet);
  };

  const handleResetRoleIds = () => {
    resetFieldValue("roleIds", dbValues.roleIds);
  };

  const setFieldError = (field, value) => {
    dispatchErrors({ type: "SET_FIELD", field, value });
  };

  const deleteFieldError = (field) => {
    dispatchErrors({ type: "REMOVE_FIELD", field });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateObject(values)) {
      handleError();
      handleErrorMessage("Por favor complete todos los campos");
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    const valueKeys = Object.keys(values);
    const editedValuesKeys = valueKeys
      .map((key) => {
        if (values[key] !== dbValues[key] && key !== "roleIds") {
          return key;
        }
      })
      .filter((key) => key !== undefined);
    const editedValues = editedValuesKeys.reduce((acc, key) => {
      acc[key] = values[key];
      return acc;
    }, {});

    const permissionsToDelete = dbValues.roleIds.filter(
      (value) => !values.roleIds.includes(value)
    );
    const permissionsToAdd = values.roleIds.filter(
      (value) => !dbValues.roleIds.includes(value)
    );
    if (
      Object.keys(editedValues).length === 0 &&
      permissionsToDelete.length === 0 &&
      permissionsToAdd.length === 0
    ) {
      handleError();
      handleErrorMessage("No ha realizado ningún cambio");
      setTimeout(() => {
        handleError();
        handleErrorMessage("");
      }, TIME_ALERT);
      return;
    }
    await handleEditUser({
      values: {
        ...editedValues,
        id: dbValues.id,
        ...(values.extension_file !== ""
          ? { extension_file: values.extension_file }
          : {}),
      },
      permissionsToDelete,
      permissionsToAdd,
      loading: () => handleLoading(),
      error: () => handleError(),
      error_message: (msj) => handleErrorMessage(msj),
      success: () => handleSuccess(),
      success_message: (msj) => handleSuccessMessage(msj),
      services: {
        editUserService,
        addRolUserService,
        deleteRolUserService,
      },
      handleRefreshData,
      handleModal,
    });
  };

  const user_id_cases = {
    edit: async () => await getUserInfo(idUser),
    profile: async () => await getUserInfo(sessionStorage.getItem("userEmail")),
  };

  useEffect(() => {
    const getAllInfo = async () => {
      await getAllCatalogs();
      await user_id_cases[type]();
    };
    getAllInfo();
  }, []);

  useEffect(() => {
    setIsValid(validateObject(values) && values?.roleIds.length > 0);
  }, [values]);

  useEffect(() => {
    if (Object.keys(dbValues).length > 0) {
      setLoadingToEdit(false);
    }
  }, [dbValues]);

  useEffect(() => {
    if (values?.phone !== "" && values?.phone?.length < 10) {
      setFieldError("phone", "Formato de número incorrecto");
      setIsValid(false);
    } else {
      deleteFieldError("phone");
    }
  }, [values?.phone, values?.email]);

  useEffect(() => {
    if (values?.area) {
      const selectedArea = positionCatalog.filter(
        (area) => area.areaId === values.area
      );
      setPositionSelectedCatalog(selectedArea ?? []);
      if (!selectedArea.find((data) => data?.id === values.position)) {
        setFieldValue("position", "");
      }
    }
  }, [positionCatalog, values.area, values.position]);

  useEffect(() => {
    if (values.birdth_date !== "") {
      if (dayjs(values.birdth_date).format("YYYY-MM-DD") !== "Invalid Date") {
        const maxAge = dayjs(values.birdth_date).isBefore(
          dayjs().subtract(120, "year")
        );
        const minAge = dayjs(values.birdth_date).isAfter(
          dayjs().subtract(18, "year")
        );
        const afterToday = dayjs(values.birdth_date).isAfter(dayjs());
        let arrayMessages = [];
        if (afterToday) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser igual o mayor a la fecha actual"
          );
        }
        if (maxAge && !afterToday && !minAge) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser mayor a 120 años"
          );
        }
        if (minAge && !afterToday && !maxAge) {
          arrayMessages.push(
            "La fecha de nacimiento ingresada no puede ser de un menor de edad"
          );
        }
        if (afterToday || maxAge || minAge) {
          setError(true);
          setErrorMessage(arrayMessages.join("\n"));
          setIsValid(false);
          setTimeout(() => {
            setError(false);
            setErrorMessage("");
          }, TIME_ALERT);
        }
      }
    }
  }, [values.birdth_date]);

  return {
    isValid,
    values,
    finished,
    loading,
    error,
    errors,
    errorMessage,
    success,
    successMessage,
    handleChange,
    handleLoading,
    handleError,
    handleErrorMessage,
    handleSelectChange,
    handleDatePickerChange,
    handleSubmit,
    handleAvatarChange,
    handleManualChange,
    rolCatalog,
    areaCatalog,
    positionSelectedCatalog,
    userData,
    handleCheckbox,
    handleResetRoleIds,
    loadingToEdit,
  };
};

export default function EditForm({ type, handleModal, idUser }) {
  const {
    isValid,
    values,
    finished,
    userData,
    errors,
    loading,
    error,
    errorMessage,
    success,
    successMessage,
    rolCatalog,
    areaCatalog,
    positionSelectedCatalog,
    handleChange,
    handleSelectChange,
    handleDatePickerChange,
    handleSubmit,
    handleAvatarChange,
    handleManualChange,
    handleCheckbox,
    handleResetRoleIds,
    loadingToEdit,
  } = useEditForm({ idUser, handleModal, type });

  return (
    <>
      <article
        className={
          "bg-white rounded m-auto d-flex flex-column user_create_form_container align-items-center edit_user-form " +
          (type === "profile" && "profile_form")
        }
      >
        <form
          style={{ maxWidth: "277px", gap: "10px" }}
          onSubmit={handleSubmit}
        >
          {type === "edit" && (
            <>
              <div
                class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start main_header_container modal_container_title"
                style={{ marginLeft: "10px;" }}
              >
                <h4 class="main_title">Usuarios</h4>
                <p class="main_subtitle">
                  Ve el listado de usuarios del nodo, agrega o edita usuarios
                </p>
              </div>
              <div className="container_modal_perfil mt-3">
                <p className="card_title" style={{ marginBottom: "23px" }}>
                  Editar usuario
                </p>
                <p className="card_text" style={{ marginBottom: "45px" }}>
                  Edita la información del usuario. Le enviaremos un correo con
                  los detalles de la edición
                </p>
              </div>
            </>
          )}
          {type === "profile" && (
            <>
              <div
                class="container-fluid d-flex flex-column p-0 justify-content-start align-items-start main_header_container modal_container_title"
                style={{ marginLeft: "10px;" }}
              >
                <h4 class="main_title">Ajustes y seguridad de la cuenta</h4>
                <p class="main_subtitle">
                  Ve y administra la configuración de tu cuenta
                </p>
              </div>
              <div className="container_modal_perfil mt-3">
                <p style={{ marginBottom: "3px" }} className={"card_title"}>
                  Perfil de usuario
                </p>
                <p style={{ marginBottom: "38px" }} className={"card_text"}>
                  Información general del usuario
                </p>
                <div className="p-0 container-info-img-profile">
                  <div className="p-0 container-img-profile">
                    <ToSkeleton
                      loading={loadingToEdit}
                      variant="circular"
                      width={54}
                      height={54}
                      style={{ minWidth: "54px", minHeight: "54px" }}
                    >
                      <img
                        className="rounded-circle w-100"
                        src={`data:image/*;base64,${userData?.user?.imagenPerfil}`}
                        alt="Profile image"
                        width={"54px"}
                        height={"54px"}
                      />
                    </ToSkeleton>
                  </div>
                  <div className="p-0">
                    <ToSkeleton loading={loadingToEdit}>
                      <p
                        className="m-0 p-0 fw-bold"
                        style={{ fontSize: "21.6px" }}
                      >{`${userData?.user?.nombre} ${userData?.user?.apellidoPaterno} ${userData?.user?.apellidoMaterno}`}</p>
                    </ToSkeleton>
                    <ToSkeleton loading={loadingToEdit}>
                      <p
                        className="m-0 p-0 text-wrap text-break"
                        style={{ fontSize: "14px", color: "#979797" }}
                      >{`${userData?.roles[0].name}`}</p>
                    </ToSkeleton>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container-form-profile container_modal_perfil">
            <ToSkeleton loading={loadingToEdit}>
              <Input
                onChange={handleChange}
                value={values.name}
                label="Nombres"
                id="name"
                name="name"
                type="text"
                valid={true}
                rounded={false}
                required={true}
                regex={REGEXInputNombre}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <Input
                onChange={handleChange}
                value={values.paternal_surname}
                label="Apellido paterno"
                id="paternal_surname"
                name="paternal_surname"
                type="text"
                valid={true}
                rounded={false}
                required={true}
                regex={REGEXInputNombre}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <Input
                onChange={handleChange}
                value={values.maternal_surname}
                label="Apellido materno"
                id="maternal_surname"
                name="maternal_surname"
                type="text"
                valid={true}
                rounded={false}
                required={true}
                regex={REGEXInputNombre}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <p style={{ marginLeft: "8px" }} className="label_form">
                Imagen de perfil
              </p>
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <SelectorAvatar
                value={values.profile_image}
                setFlieProp={(value) =>
                  handleAvatarChange({
                    name: "profile_image",
                    value,
                  })
                }
                setExtensionFileProp={(value) =>
                  handleManualChange({
                    name: "extension_file",
                    value,
                  })
                }
                required={true}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <DatePickerInput
                onChange={handleDatePickerChange}
                value={values.birdth_date}
                label="Fecha de Nacimiento"
                id="birdth_date"
                valid={true}
                rounded={false}
                maxDate={dayjs().subtract(1, "day")}
                required={true}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <InputPhone
                onChange={handleChange}
                value={values.phone}
                label="Teléfono"
                id="phone"
                name="phone"
                type="phone"
                valid={!!!errors?.phone}
                rounded={false}
                tooltip={true}
                required={true}
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <div
                style={{ opacity: "0.5", cursor: "pointer" }}
                className="position-relative container-fluid p-0"
              >
                <div
                  className={`position-absolute input_label input_label_outside_input`}
                >
                  Correo electrónico
                </div>
                <div
                  className={`bg-transparent input border-black`}
                  style={{ overflow: "auto" }}
                >
                  {values.email}
                </div>
              </div>
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              {type === "profile" ? (
                <div className="container p-0 m-0 pe-none opacity-50">
                  <InputToggle
                    label="Roles"
                    value={values.roleIds[0] ?? ""}
                    description={values.roleIds
                      .map((rolId) => rolCatalog.find(({ id }) => id === rolId))
                      .reduce(
                        (label, currentValue) =>
                          `${label !== "" ? `${label}-` : label}${
                            currentValue.name
                          }`,
                        ""
                      )}
                    disabled={false}
                    required={true}
                  ></InputToggle>
                </div>
              ) : (
                <InputToggle
                  label="Roles"
                  value={values.roleIds[0] ?? ""}
                  description={values.roleIds
                    .map((rolId) => rolCatalog.find(({ id }) => id === rolId))
                    .reduce(
                      (label, currentValue) =>
                        `${label !== "" ? `${label}-` : label}${
                          currentValue.name
                        }`,
                      ""
                    )}
                  disabled={false}
                >
                  <AssignRolesForm
                    handleResetRoleIds={handleResetRoleIds}
                    handleCheckbox={handleCheckbox}
                    roles={rolCatalog}
                    values={values.roleIds}
                  />
                </InputToggle>
              )}
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <Select
                id={`area`}
                name={`area`}
                label="Área"
                value={values.area}
                handleSetValue={({ field, value }) => {
                  handleSelectChange({
                    name: field,
                    value: value,
                  });
                }}
                options={areaCatalog}
                valueKey="id"
                labelKey="name"
                rounded={false}
                customStyles={{
                  border: "1px solid #000000",
                }}
                required
              />
            </ToSkeleton>
            <ToSkeleton loading={loadingToEdit}>
              <Select
                id={`position`}
                name={`position`}
                label="Puesto"
                value={values.position}
                handleSetValue={({ field, value }) => {
                  handleSelectChange({
                    name: field,
                    value: value,
                  });
                }}
                options={positionSelectedCatalog}
                valueKey="id"
                labelKey="name"
                rounded={false}
                customStyles={{
                  border: "1px solid #000000",
                }}
                required
              />
            </ToSkeleton>
          </div>
          <div
            className="container d-flex justify-content-end container_modal_perfil"
            style={{ position: "relative", bottom: "0px", marginTop: "11px" }}
          >
            <ToSkeleton loading={loadingToEdit}>
              <p
                style={{ fontSize: "8px", color: "#2D2D2D99" }}
                className="label_form m-0"
              >
                Usuario dado de alta el{" "}
                {userData?.user?.fechaAlta
                  ? dayjs(userData.user?.fechaAlta)
                      .format("DD/MM/YYYY")
                      .toString()
                  : ""}
              </p>
            </ToSkeleton>
          </div>
          <div
            style={{ marginBottom: "10px", marginTop: "28px" }}
            className="d-flex gap-3 container_modal_perfil"
          >
            <button
              disabled={!isValid}
              className={`button ${
                isValid ? "button_active" : "button_disabled"
              }`}
              type="submit"
              data-testid="editar-actualizar-usuario"
            >
              {type === "profile" ? "Editar" : "Actualizar"}
            </button>
            <button
              onClick={handleModal}
              className="cancel_button"
              type="button"
              data-testid="cacncelar-button"
            >
              Cancelar
            </button>
          </div>
        </form>
      </article>
      {loading && <Loader />}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </>
  );
}
