import React, {
  useState,
  useMemo,
  useEffect,
  createContext,
  useRef,
  useCallback,
} from "react";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import {
  downloadInfoCargaDB,
  getAllLogsServiceDB,
  getNodeCatalog,
} from "../../services/api";
import { Permisos } from "../../services/permisos.js";
import ExportData from "./ExportData";
import FilterForm from "./FilterForm";
import {
  formatDateToGMT06,
  formatToTwoDecimals,
  getDefaultInitialDateValues,
  handleGetCatalogs,
  TIME_ALERT,
  useValuesReducer,
  validateEmptyValues,
} from "../../services/data";
import { useErrorReducer } from "../../services/data.js";
import Pagination from "../Table/Pagination.jsx";
import usePaginationBack from "../Table/usePaginationBack.js";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import LimpiarFiltros from "../Sharing/LimpiarFiltros.jsx";
import CopyCell from "../Transactions/CopyCell.jsx";

dayjs.extend(isoWeek);

export const CargasBDContext = createContext();
const get_INITIAL_VALUES = () => {
  return {
    search: "",
    ...getDefaultInitialDateValues(),
    response_time: [],
    first_mount: true,
    all_nodes: "",
    all: "",
  };
};
const INITIAL_VALUES = get_INITIAL_VALUES();

export default function Body() {
  const {
    createHandleChange,
    createHandleDatePickerChange,
    daysController,
    values,
  } = useValuesReducer(INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [scoreLimits, setScoreLimits] = useState({
    min: 10,
    max: 100,
  });
  const [responseTimeLimits, setResponseTimeLimits] = useState({
    min: 10,
    max: 100,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [nodeCatalog, setNodeCatalog] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(new AbortController());
  const [orderConfig, setOrderConfig] = useState({
    key: "nodo_name",
    direction: "desc",
  });
  const { errorValues, handleError } = useErrorReducer();

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();

  // Handlers
  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = useCallback((msj) => {
    setSuccessMessage(msj);
  }, []);

  const handleChange = createHandleChange(handleResetPagination);

  const handleDatePickerChange = createHandleDatePickerChange(
    handleResetPagination
  );

  useEffect(() => {
    if (values.days !== "") {
      daysController[values.days]({
        start_date: "start_date",
        start_hours: "start_hours",
        end_date: "end_date",
        end_hours: "end_hours",
      });
    }
  }, [daysController, values.days]);

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  const handlePageInformation = ({ totalPages, page }) => {
    const HasPreviousPage = page > 1;
    const HasNextPage = page < totalPages;
    return {
      HasPreviousPage,
      HasNextPage,
      NextPageNumber: HasNextPage ? page + 1 : null,
      PreviousPageNumber: HasPreviousPage ? page - 1 : null,
    };
  };

  const handleGetCargasDB = useCallback(
    async ({ ...props }) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        handleLoading();
        const noEmptyValues = validateEmptyValues(values);
        const response = await getAllLogsServiceDB({
          // Consumios el endpoint
          page: pagination.page,
          ...noEmptyValues,
          signal: abortControllerRef.current.signal,
          OrderAscending: orderConfig.direction,
          OrderBy: orderConfig.key,
          ...(noEmptyValues.all_nodes === "Todos los nodos"
            ? { all_nodes: "" }
            : { all_nodes: noEmptyValues.all_nodes }),
        });
        if (!response.success) {
          handleLoading();
          if (response.name === "AbortError") {
            setTableData([]);
            console.log("Request canceled");
            return;
          }
          handleError("increment", response.message);
          setTimeout(() => {
            handleError("decrement", "");
          }, TIME_ALERT);
          return;
        }
        handleLoading();
        setTableData(response?.data?.list ?? []);
        handleValidateTotalPages({
          totalPages: response?.data?.totalPages,
          page: response?.data?.page,
          totalElements: response?.data?.totalElements,
        });
        setScoreLimits({
          min: response?.data?.min_super_score,
          max: response?.data?.max_super_score,
        });
        setResponseTimeLimits({
          max: response?.data?.max_tiempo_respuesta,
          min: response?.data?.min_tiempo_respuesta,
        });
        return;
      } catch (error) {
        console.log("Error en el handler");
        console.error(error);
        if (error.name === "AbortError") {
          console.log("Request canceled");
          return;
        }
        setLoading(false);
        setTableData([]);
        handleError("increment", "Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleError("decrement", "");
        }, TIME_ALERT);
        return;
      }
    },
    [handleValidateTotalPages, pagination.page, values, orderConfig]
  );

  const availableDownloads = {
    excel: {
      params: "&formato=xls",
      name: "CargaBD.xls",
    },
    csv: {
      params: "&formato=csv",
      name: "CargaBD.csv",
    },
    pdf: {
      params: "&formato=pdf",
      name: "CargaBD.pdf",
    },
  };

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        handleLoading();
        const response = await downloadInfoCargaDB({
          signal: abortControllerRef.current.signal,
          ...values,
          download: availableDownloads[selected],
          OrderAscending: orderConfig.direction,
          OrderBy: orderConfig.key,
          ...(values.all_nodes === "Todos los nodos"
            ? { all_nodes: "" }
            : { all_nodes: values.all_nodes }),
        });
        if (!response.success) {
          handleLoading();
          handleError("increment", response.message);
          setTimeout(() => {
            handleError("decrement", response.message);
          }, TIME_ALERT);
          return;
        }
        handleLoading();
        handleSuccess();
        handleSuccessMessage(
          "La exportación de datos se ha realizado con éxito"
        );
        setTimeout(() => {
          handleSuccess();
          handleModal();
          handleSuccessMessage("");
        }, TIME_ALERT);
      } catch (error) {
        console.log(error);
        handleLoading();
        if (
          error.toString().includes("NETWORK_CONNECTION") ||
          error.toString().includes("Failed to fetch")
        )
          handleError(
            "increment",
            "No hay conexión a Internet. Por favor, verifica tu conexión."
          );
        else handleError("increment", "Lo sentimos ocurrió un error");
        setTimeout(() => {
          handleError("decrement", "Lo sentimos ocurrió un error");
        }, TIME_ALERT);
        return;
      }
    },
    [values, orderConfig]
  );

  useEffect(() => {
    handleGetCargasDB({});
  }, [pagination.page, values, orderConfig]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Nodo",
        selector: (row) => row?.nodo_name ?? "",
        key_name: "nodo_info.nodo_name",
        service: handleOrderByColumns,
      },
      {
        name: "ID de carga",
        selector: (row) => {
          const idCarga = row?.idCarga ?? "";
          return idCarga.length > 10
            ? `${idCarga.slice(0, 6)}...${idCarga.slice(-4)}`
            : idCarga;
        },
        custom_cell: (data) =>
          data?.idCarga ? <CopyCell text={data.idCarga} /> : "",
        key_name: "idCarga",
        service: handleOrderByColumns,
      },
      {
        name: "Fecha/Hora de carga",
        selector: (row) =>
          (dayjs(formatDateToGMT06(row?.fecha_alta)).format(
            "YYYY-MM-DD HH:mm:ss"
          ) ?? "") + " (GMT-6)",
        key_name: "fecha_alta",
        service: handleOrderByColumns,
      },
      {
        name: "Total de registros",
        selector: (row) => row?.total_registros ?? "",
        key_name: "total_registros",
        service: handleOrderByColumns,
      },
      {
        name: "Total de errores",
        selector: (row) => row?.total_errores ?? "",
        service: handleOrderByColumns,
        key_name: "total_errores",
      },
      {
        name: "Total de duplicados",
        selector: (row) => row?.total_duplicados ?? "",
        service: handleOrderByColumns,
        key_name: "total_duplicados",
      },
      {
        name: "Prom. índice calidad de registro",
        selector: (row) => formatToTwoDecimals(row?.avgQualityScore) ?? "",
        service: handleOrderByColumns,
        key_name: "avgQualityScore",
      },
    ],
    [handleOrderByColumns]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    pagination,
  ]);

  return (
    <CargasBDContext.Provider
      value={{ dataTable, handleError, handleDownloadData, nodeCatalog }}
    >
      {
        <div
          className="container-fluid d-flex justify-content-end padding-right-0 p-0 align-items-end justifu-content-between flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
          style={{ marginTop: "12px" }}
        >
          {Permisos.verificarPermiso("Cargas BD", "Buscar") && (
            <FilterForm
              handleDatePickerChange={handleDatePickerChange}
              handleChange={handleChange}
              values={values}
              scoreLimits={scoreLimits}
              responseTimeLimits={responseTimeLimits}
            />
          )}
          <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap mt-3">
            <LimpiarFiltros
              handleChange={handleChange}
              INITIAL_VALUES={get_INITIAL_VALUES()}
              modulo="Cargas BD"
            />
            {Permisos.verificarPermiso("Cargas BD", "Exportar") && (
              <ExportData />
            )}
          </div>
        </div>
      }
      <div
        style={{ marginTop: "11px" }}
        className="container-fluid d-flex flex-column p-0 dco-table-gestion-container"
      >
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {errorValues.error !== 0 && (
        <AlertaGeneral type={"error"}>{errorValues.message}</AlertaGeneral>
      )}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
    </CargasBDContext.Provider>
  );
}
