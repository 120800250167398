import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};
const useControlWidth = (refFilter) => {
  const windowWidth = useWindowWidth();
  const [widthFilterStatic, setWidthFilterStatic] = useState("100%");
  useEffect(() => {
    obtenerAncho();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleResize = () => {
    obtenerAncho();
  };
  const obtenerAncho = () => {
    let width = refFilter?.current?.getBoundingClientRect()?.width;
    if (width) {
      if (windowWidth >= 3840) {
        width = width / 2.5;
      } else if (windowWidth >= 2560) {
        width = width / 1.8;
      } else if (windowWidth >= 1920) {
        width = width / 1.3;
      } else if (windowWidth >= 1600) {
        width = width / 1.1;
      } else if (windowWidth >= 1400) {
        width = width / 1.05;
      }
      setWidthFilterStatic(width);
    }
  };
  return {
    widthFilterStatic,
  };
};

const SlideWrapper = ({ children, values }) => {
  const swiperRef = useRef();
  const containerRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update(); // Forzar recalculo de Swiper
    }
  }, [values]);

  const { widthFilterStatic } = useControlWidth(containerRef);
  return (
    <div
      style={{ width: "100%", maxWidth: widthFilterStatic }}
      ref={containerRef}
    >
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={10}
        navigation={{
          disabledClass: "swiper_navigation_disabled",
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="mySwiper"
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={index}>{child}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SlideWrapper;
