import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputPassword from "../Inputs/InputPassword";

const FormPassword = ({
  onCancel,
  onLogin,
  setPasswordProp,
  userData,
  errorForButton,
}) => {
  const [password, setPassword] = useState("");
  useEffect(() => {
    setPasswordProp(password);
  }, [password]);

  return (
    <div
      className={`row m-0`}
      style={{
        transition: "all 0.4s ease-in-out",
        width: "100%",
        transform: `translateX(0%)`,
      }}
    >
      <div className="col p-0  d-flex flex-column ">
        <p className="fw-bold margin-top-69 margin-bottom-40">
          {`Hola ${userData?.firstName}, captura tu contraseña para ingresar`}
        </p>
        <InputPassword setPasswordProp={setPassword} />
        <div className="mt-2 mb-4 forgot_link_container">
          <Link to="/recuperar-contrasena" className={`forgot_link fw-normal`}>
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
        <div className="d-flex gap-3">
          <button
            className={`button ${
              password ? "button_active" : "button_disabled"
            }`}
            disabled={!password}
            type="submit"
            onClick={onLogin}
            data-testid="continue-password-button"
          >
            {errorForButton ? "Volver a intentar" : "Ingresar"}
          </button>
          <button
            className={"cancel_button"}
            type="button"
            onClick={onCancel}
            data-testid="cancel-button"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormPassword;
