import React, { useState } from "react";

const InputPassword = ({
  setPasswordProp = () => {},
  labelPassword = "Contraseña",
  success = false,
  danger = false,
}) => {
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const manejadorPassword = (e) => {
    setPassword(e.target.value);
    setPasswordProp(e.target.value);
  };
  const alternarMostrarPassword = (e) => {
    setPasswordVisible((prevState) => !prevState);
  };
  return (
    <div
      // className="position-relative mt-3 container-fluid p-0">
      className="position-relative container-fluid p-0"
    >
      <div
        className={`position-absolute pe-none input_label ${
          focusInput ? "input_label_outside_input" : "input_label_inside_input"
        }`}
      >
        {labelPassword}
      </div>
      <input
        name="password"
        autoComplete="off"
        onChangeCapture={manejadorPassword}
        type={passwordVisible ? "text" : "password"}
        className={`input bg-transparent border-black ${
          danger && "border-danger"
        } ${success && "border-success"}`}
        onFocus={() => {
          setFocusInput(true);
        }}
        onBlur={() => {
          !password && setFocusInput(false);
        }}
        value={password}
        data-testid={labelPassword ?? ""}
      />
      <button
        onClick={alternarMostrarPassword}
        className="input_watch_password_button bg-transparent"
        type="button"
        data-testid="password-button"
      >
        <img
          src={
            passwordVisible
              ? "/images/dicio/invisible.webp"
              : "/images/dicio/visible.webp"
          }
          alt=""
          width="14"
          height="auto"
        />
      </button>
    </div>
  );
};

export default InputPassword;
