import React, { useState, useEffect } from "react";
import InputPassword from "../Inputs/InputPassword";
import InputNewPassword from "../Inputs/InputNewPassword";
import FormTwoFactor from "./FormTwoFactor";
import AlertaGeneral from "../Alerts/AlertaGeneral.jsx";

import {
  actualizarPasswordUser,
  getInfoUser,
  resendCodeTwoFactor,
} from "../../services/api";
import ModalCardPages from "../Modal/ModalCardPages";
import ModalCardMedPages from "../Modal/ModalCardMedPages";
import Loader from "../Loader/Loader";
import { TIME_ALERT, verificarPassword } from "../../services/data";
import InputPasswordConfirm from "../Inputs/InputPasswordConfirm";
const CambioPassword = ({
  onCancel = () => {},
  onActualizar = () => {},
  emailUser = "",
  disabledCancel = false,
}) => {
  const [passActual, setPassActual] = useState("");
  const [passNew, setPassNew] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [banderaPass, setBanderaPass] = useState("blank");
  const [pedirTwoFactor, setPedirTwoFactor] = useState(false);
  const [nombreUser, setNombreUser] = useState("");
  const [codigo, setCodigo] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertsuccess, setAlertsuccess] = useState("");

  useEffect(() => {
    obtenerInfoUser();
  }, []);
  const obtenerInfoUser = async () => {
    try {
      setLoading(true);
      let res = await getInfoUser(sessionStorage.getItem("userEmail"));
      if (res?.data?.isSucceed) {
        setNombreUser(res.data?.data?.user?.nombre);
      }
    } catch (error) {
      if (error?.message)
        onActualizar({ OK: false, message: error?.message, data: error });
      else onActualizar({ OK: false, message: "Error de Red", data: error });
    } finally {
      setLoading(false);
    }
  };

  const validarPassword = () => {
    return (
      passActual &&
      passNew &&
      (banderaPass === "strong" || banderaPass === "green") &&
      passConfirm
    );
  };
  const validacionPasswordPrevioEnvio = () => {
    if (passActual === passNew) {
      onActualizar({
        OK: false,
        message: "Las contraseñas no pueden ser iguales",
        data: "Iguales Password",
        type: "password",
      });
      return false;
    } else if (passConfirm !== passNew) {
      onActualizar({
        OK: false,
        message: "La contraseña de confirmación no coincide",
        data: "Password No Coincide",
        type: "password",
      });
      return false;
    } else {
      return true;
    }
  };
  const solicitarCodigoTwoFactor = async () => {
    try {
      setLoading(true);
      let res = await resendCodeTwoFactor(sessionStorage.getItem("userEmail"));

      if (res.data.isSucceed) setPedirTwoFactor(true);
      else
        onActualizar({
          OK: false,
          message: "Solicitud de Código Fallida",
          data: res,
        });
    } catch (error) {
      onActualizar({
        OK: false,
        message: "Solicitud de Código Fallida",
        data: error,
      });
    } finally {
      setLoading(false);
    }
  };
  const reenviarCodigoTwoFactor = async () => {
    try {
      setLoading(true);
      await resendCodeTwoFactor(sessionStorage.getItem("userEmail"));
      setAlertsuccess("El envío del código se ha realizado con éxito");
      setTimeout(() => {
        setAlertsuccess("");
      }, TIME_ALERT);
    } catch (error) {
      onActualizar({
        OK: false,
        message: "Solicitud de Código Fallida",
        data: error,
      });
    } finally {
      setLoading(false);
    }
  };
  const actualizarPassword = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      if (!validacionPasswordPrevioEnvio()) return;
      setLoading(true);
      const { data } = await actualizarPasswordUser(
        emailUser,
        passActual,
        passNew,
        codigo ? codigo : ""
      );
      if (data.isSucceed) {
        onActualizar({ OK: true, message: "OK", data: data });
        sessionStorage.setItem(
          "lastPasswordChangedDate",
          new Date().toISOString()
        );
      } else {
        if (data.messages?.Password) {
          onActualizar({
            OK: false,
            message: data.messages?.Password[0],
            data: data,
            type: "password",
          });
        } else if (data.messages?.password) {
          onActualizar({
            OK: false,
            message: data.messages?.password[0],
            data: data,
            type: "password",
          });
        } else if (
          data.messages?.Code &&
          data.messages?.Code[0] === "Se requiere el codigo 2FA"
        ) {
          await solicitarCodigoTwoFactor();
        } else if (
          data.messages?.Code &&
          data.messages?.Code[0] === "Invalid Code"
        ) {
          onActualizar({
            OK: false,
            message: "El código ingresado es incorrecto.",
            data: data,
            type: "Code",
          });
        } else if (data.messages?.Code) {
          onActualizar({
            OK: false,
            message: data.messages?.Code[0],
            data: data,
            type: "Code",
          });
        } else if (data.messages) {
          let keys = Object.keys(data.messages);
          if (keys.length > 0) {
            onActualizar({
              OK: false,
              message: data.messages[keys[0]],
              data: data,
            });
          } else
            onActualizar({
              OK: false,
              message: "Actualización Fallida",
              data: data,
            });
        } else {
          onActualizar({
            OK: false,
            message: "Actualización Fallida",
            data: data,
          });
        }
      }
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        onActualizar({
          OK: false,
          message:
            "No hay conexión a Internet. Por favor, verifica tu conexión.",
          data: error,
        });
        setTimeout(() => {
          onActualizar({ OK: false, message: "", data: error });
        }, TIME_ALERT);
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!pedirTwoFactor ? (
        <ModalCardPages>
          <div className="d-flex flex-column gap-3 align-items-start col-6 p-0 padding_modal">
            <h4 className="card_title">Actualiza tu contraseña</h4>
            <p className="card_text">
              Para poder actualizar tu contraseña, necesitamos que ingreses tu
              contraseña actual por motivos de seguridad
            </p>
            <div className="container-fluid p-0 d-flex flex-column container-form-new-password">
              <InputPassword
                labelPassword="Contraseña actual"
                setPasswordProp={setPassActual}
              />
              <div className="container-fluid p-0">
                <p className="">Elige tu nueva contraseña</p>
              </div>
              <InputNewPassword
                setBanderaProp={setBanderaPass}
                setPasswordProp={setPassNew}
              />
              <InputPasswordConfirm
                labelPassword="Confirmar nueva contraseña"
                setPasswordProp={setPassConfirm}
                danger={passConfirm && passConfirm !== passNew}
                coincidence={passConfirm && passConfirm === passNew}
              />
              <div
                className="container-fluid px-0"
                style={{ marginTop: "58px" }}
              >
                <button
                  type="button"
                  className={`button ${
                    validarPassword() ? "button_active" : "button_disabled"
                  } `}
                  disabled={!validarPassword()}
                  onClick={actualizarPassword}
                  data-testid="button-finish-actualizar-password"
                >
                  Actualizar
                </button>
                {!disabledCancel && (
                  <button
                    type="button"
                    className="cancel_button"
                    onClick={onCancel}
                    data-testid="cancel-button"
                  >
                    Cancelar
                  </button>
                )}
              </div>
            </div>

            {/*<div className="container-fluid d-flex justify-content-center d-none">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p style={{ fontSize: "12px" }} className="fw-lighter m-0">
            ¿Necesitas ayuda?
          </p>
          <a
            className="fw-bold forgot_link"
            target="_blank"
            href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
          >
            Presiona aquí
          </a>
        </div>
      </div>*/}
          </div>
        </ModalCardPages>
      ) : (
        <ModalCardMedPages>
          <FormTwoFactor
            userName={nombreUser}
            cancelar={onCancel}
            reenviarCodigo={reenviarCodigoTwoFactor}
            setCodigo={setCodigo}
            enviarCodigo={actualizarPassword}
            passwordMessage={true}
          />
        </ModalCardMedPages>
      )}
      {loading && <Loader />}
      {alertsuccess && (
        <AlertaGeneral type={"success"}>{alertsuccess}</AlertaGeneral>
      )}
    </>
  );
};

export default CambioPassword;
