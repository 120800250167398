import React, { useEffect, useState } from "react";
import { endpoints, loadEndpoints } from "../../services/api";

const LoginCard = ({ children }) => {
  const [logo, setLogo] = useState("/images/dicio/logo.webp");
  const [dimensionesLogo, setDimensionesLogo] = useState({});

  useEffect(() => {
    const cargarVariables = async () => {
      await loadEndpoints();
      if (endpoints?.view_config?.images?.logo)
        setLogo(endpoints?.view_config?.images?.logo);

      if (endpoints?.view_config?.dimensiones?.logo)
        setDimensionesLogo(endpoints?.view_config?.dimensiones?.logo);
    };
    cargarVariables();
  }, []);

  return (
    <div
      className={`vh-100 container-fluid d-flex justify-content-center p-3 align-items-center login-container-card`}
    >
      <div
        // className={`container-fluid bg-white d-flex flex-column justify-content-between p-3 p-md-4 p-lg-5 align-items-center login_card`}
        className={`container-fluid bg-white d-flex flex-column p-3 p-md-4 p-lg-5 align-items-center login_card`}
      >
        <img
          src={logo}
          alt="DICIO  Alliance logo"
          className={`login_logo`}
          style={{
            ...(dimensionesLogo?.w ? { width: dimensionesLogo?.w } : {}),
            ...(dimensionesLogo?.h ? { height: dimensionesLogo?.h } : {}),
          }}
        />
        {children}
        <div className="d-flex flex-column justify-content-center align-items-center d-none">
          <p style={{ fontSize: "12px" }} className="fw-lighter m-0">
            ¿Necesitas ayuda?
          </p>
          <a
            className={`fw-bold forgot_link`}
            target="_blank"
            href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
          >
            Presiona aquí
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
