import React, { useRef, useState, useContext } from "react";
import Modal from "../Modal/Modal";
import CreateForm from "./CreateForm";
import { UserContext } from "../Users/Body";

const useUserContext = () => useContext(UserContext);

export default function CreateUser() {
  const [isFinished, setIsFinished] = useState(false);
  const { handleRefreshData } = useUserContext();
  const modalRef = useRef(null);
  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };
  const handleProcess = () => {
    setIsFinished((prevState) => !prevState);
  };
  return (
    <>
      <Modal ref={modalRef}>
        {!isFinished && (
          <CreateForm handleProcess={handleProcess} handleModal={handleModal} />
        )}
        {isFinished && (
          <article
            className="bg-white rounded m-auto d-flex flex-column user_create_form_succeed_container position-absolute top-50 start-50 translate-middle modal_create_user"
            style={{
              paddingTop: "62px",
              paddingRight: "81px",
              paddingLeft: "81px",
            }}
          >
            <div
              style={{ maxWidth: "277px" }}
              className="d-flex flex-column align-items-start"
            >
              <p style={{ marginBottom: "19px" }} className="card_title mt-0">
                Crear un usuario
              </p>
              <p style={{ marginBottom: "18px" }} className="card_text">
                Se ha realizado la creación del usuario con éxito, se le enviará
                un correo a tu nuevo usuario para terminar de configurar su
                cuenta
              </p>
              <button
                data-testid="close-button"
                className="button button_active"
                type="button"
                onClick={() => {
                  handleRefreshData();
                  handleModal();
                  handleProcess();
                }}
              >
                Cerrar
              </button>
            </div>
          </article>
        )}
      </Modal>
      <button
        data-testid="crearUsuario-button"
        type="button"
        className={`d-flex flex-nowrap text-nowrap gap-1 create_button`}
        onClick={handleModal}
      >
        Crear usuario
      </button>
    </>
  );
}
