import React, { useContext } from 'react';
import { NodeDashboardContext } from './NodoStatisticsData';
import Select from '../Inputs/Select';
import SlideWrapper from '../Sharing/SlideWrapper';
import { useTimeFilters } from '../Sharing/TimeFilters';

const useDashboardNodeContext = () => {
  const context = useContext(NodeDashboardContext);

  const nodesCatalog = context?.nodesCatalog || null;

  return {
    ...context,
    nodesCatalog,
  };
};

export default function FilterDashboard({
  type = 'consorcio',
  values,
  handleDatePickerChange = () => {},
  handleChange = () => {},
}) {
  const { nodesCatalog } = useDashboardNodeContext();
  const timeFilters = useTimeFilters({
    handleDatePickerChange,
    handleChange,
    values,
  });

  let filters = timeFilters;

  if (type === 'node') {
    filters = [
      <div
        style={{ width: 'fit-content' }}
        className='container-fluid p-0 m-0'
        data-testid='filtro-nodos-select'
      >
        <Select
          id={`node_name`}
          name={`node_name`}
          label=''
          value={values.node_name}
          handleSetValue={({ field, value }) => {
            handleChange({
              name: field,
              value,
            });
          }}
          options={nodesCatalog}
          valueKey='nodo_name'
          labelKey='nodo_name'
          arrow='dark'
          arrowWidth={5}
          rounded={true}
        />
      </div>,
      ...filters,
    ];
  }

  return (
    <form
      className='container-fluid px-1 justify-content-end d-flex gap-1 gestion_filter_form'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <SlideWrapper values={values}>{filters}</SlideWrapper>
      <button type='submit' className='d-none'></button>
    </form>
  );
}
