import React from "react";
import ToSkeleton from "../Skeleton/ToSkeleton";

import Skeleton from "@mui/material/Skeleton";
import { getByIDService } from "../../services/api";
import { useState, useEffect } from "react";
import { TIME_ALERT } from "../../services/data";

export const handleUserByID = async ({
  id,
  service,
  error,
  error_message,
  setter,
  loading,
}) => {
  try {
    const response = await service(id);
    if (!response.success) {
      error();
      error_message(response.message);
      setTimeout(() => {
        error();
        error_message("");
      }, TIME_ALERT);
      console.error("Error request");
      return;
    }
    setter(response);
  } catch (err) {
    error();
    error_message("Lo sentimos ocurrió un error, intente más tarde");
    setTimeout(() => {
      error();
      error_message("");
    }, TIME_ALERT);
    console.error("Error request");
    console.error(err);
    return;
  }
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

const useUserPersonalInfo = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const windowWidth = useWindowWidth();
  useEffect(() => {
    if (windowWidth > 472) {
      handleUserByID({
        id: sessionStorage.getItem("userEmail"),
        error: () => {},
        error_message: (msj) => {},
        service: getByIDService,
        loading: () => {},
        setter: setUserData,
      });
    }
  }, []);

  useEffect(() => {
    if (userData) setLoading(false);
  }, [userData]);

  return {
    userData,
    loading,
  };
};

export default function Header({ styles, children, type = "page" }) {
  const { userData, loading } = useUserPersonalInfo();

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        left: "0",
        background: "#FFFFFF",
        zIndex: "99",
        padding: `${type === "page" ? "35px 0 0px 0" : "0px 0 0px 0"}`,
        ...(type === "page" ? { paddingLeft: "0px" } : []),
      }}
      className="container-fluid d-flex justify-content-start align-items-start gap-1 "
    >
      <div
        style={styles ? styles : { marginLeft: "10px" }}
        className="container-fluid d-flex flex-column p-0 justify-content-start align-items-start main_header_container"
      >
        {children}
      </div>
      {type === "page" ? (
        <div
          style={{ gap: "15px", paddingRight: "10px" }}
          className="d-flex align-items-center user_personal_info_container"
        >
          {loading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={30}
              height={30}
              style={{ minWidth: "30px", minHeight: "30px" }}
            />
          ) : (
            <img
              src={`data:image/*;base64,${userData?.data?.imagenPerfil}`}
              className="rounded-circle"
              alt="User prfile photo"
              width={"30px"}
              height={"30px"}
            />
          )}
          <ToSkeleton loading={loading}>
            <div className="d-flex flex-column">
              <p className="m-0 user_heading_name_info">{`${userData?.data?.nombre} ${userData?.data?.apellidoPaterno} ${userData?.data?.apellidoMaterno}`}</p>
              <p className="m-0 user_heading_email_info">{userData?.rol}</p>
            </div>
          </ToSkeleton>
        </div>
      ) : null}
    </div>
  );
}
