import React, { useState, useEffect } from "react";
import ModalCardPagesSmall from "../Modal/ModalCardPagesSmall";
import ModalCardMedPages from "../Modal/ModalCardMedPages";
import {
  activarTwoFactor,
  endpoints,
  enviarCodigoTwoFactor,
  loadEndpoints,
} from "../../services/api";
import AlertaGeneral from "../Alerts/AlertaGeneral.jsx";
import FormTwoFactor from "./FormTwoFactor.jsx";
import Loader from "../Loader/Loader.jsx";
import { TIME_ALERT } from "../../services/data.js";
const TwoFactor = ({
  activar = true,
  email,
  cerrarModal = () => {},
  userName = "",
  codeValid,
}) => {
  const [pedirCodigo, setPedirCodigo] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [alertsuccess, setAlertsuccess] = useState("");
  const [intentos, setIntentos] = useState(0);
  const [loading, setLoading] = useState(false);
  const limiteIntentos = 3;
  const tiempoEsperaMs = 15 * 60 * 1000;
  const [codInvalido, setCodInvalido] = useState(false);
  const [logo, setLogo] = useState("/images/dicio/logo.webp");
  const [dimensionesLogo, setDimensionesLogo] = useState({});

  useEffect(() => {
    const cargarVariables = async () => {
      await loadEndpoints();
      if (endpoints?.view_config?.images?.logo)
        setLogo(endpoints?.view_config?.images?.logo);

      if (endpoints?.view_config?.dimensiones?.logo)
        setDimensionesLogo(endpoints?.view_config?.dimensiones?.logo);
    };
    cargarVariables();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, TIME_ALERT);
    }
  }, [error]);
  useEffect(() => {
    if (intentos >= limiteIntentos) {
      sessionStorage.setItem("bloqueoCodigoChangePassword", "true");
      setTimeout(() => {
        sessionStorage.setItem("bloqueoCodigoChangePassword", "false");
      }, tiempoEsperaMs);
    }
  }, [intentos, tiempoEsperaMs]);
  useEffect(() => {
    let bloqueo = sessionStorage.getItem("bloqueoCodigoChangePassword");
    if (bloqueo == "true") {
      setIntentos(limiteIntentos + 1);
      setPedirCodigo(true);
    }
  }, []);

  const activarTwoFactorClick = async (
    props = { reenviar_twoFactor: false }
  ) => {
    try {
      setLoading(true);
      let result = await activarTwoFactor(email, activar);
      if (result.data.isSucceed) {
        setPedirCodigo(true);
        if (props?.reenviar_twoFactor) {
          setAlertsuccess("El envío del código se ha realizado con éxito");
          setTimeout(() => {
            setAlertsuccess("");
          }, TIME_ALERT);
        }
      } else {
        setError("Problema al solicitar la activación");
      }
    } catch (error) {
      if (error?.message) setError(error?.message);
      else setError("Problema al solicitar la activación");
    } finally {
      setLoading(false);
    }
  };
  const enviarCodigo = async () => {
    setCodInvalido(false);

    try {
      setLoading(true);
      let result = await enviarCodigoTwoFactor(codigo, email, activar);
      if (result?.data?.isSucceed) {
        setSuccess(true);
        cerrarModal(true);
      } else {
        setError(
          "El código ingresado es incorrecto o ha expirado. Intentos restantes " +
            (limiteIntentos - intentos - 1)
        );
        setIntentos((prevState) => prevState + 1);
        setCodInvalido(true);
      }
    } catch (error) {
      if (error?.message) setError(error?.message);
      else setError("Problema al solicitar la activación");
    } finally {
      setLoading(false);
    }
  };
  const cancelar = async () => {
    cerrarModal();
  };
  const reenviarCodigo = async () => {
    if (!alertsuccess) {
      activarTwoFactorClick({ reenviar_twoFactor: true });
      /**/
    }
  };

  return (
    <>
      {!pedirCodigo ? (
        <ModalCardPagesSmall className="two_factor_modal_class">
          <div className="overflow-x-hidden modal-activate-twofactor">
            <div
              className="row m-0 mb-4 container-mjs-twofactor"
              style={{ minWidth: "100%" }}
            >
              <div className="d-flex flex-column h-100 justify-content-between p-0">
                <h4 className="card_title" style={{ marginBottom: "10px" }}>
                  {activar ? "Activa" : "Desactiva"} el doble factor de
                  autenticación
                </h4>
                <p className="card_text mb-4 mt-3">
                  {activar
                    ? `Por tu seguridad, si activas esta
                  opción enviaremos un código a tu correo electrónico cada vez
                  que inicies sesión`
                    : `Vamos a desactivar la segunda capa de seguridad en tu cuenta, si estas de acuerdo da clic en el botón de confirmar`}
                </p>
                <div className="container-fluid p-0 d-flex gap-1">
                  <button
                    type="submit"
                    className="button button_active"
                    style={{ height: "fit-content" }}
                    onClick={activarTwoFactorClick}
                    data-testid="button-activar-two-factor"
                  >
                    {activar ? "Activar ahora" : "Confirmar"}
                  </button>
                  <button
                    type="button"
                    className="cancel_button"
                    style={{ height: "fit-content" }}
                    onClick={cancelar}
                    data-testid="Cancelar"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalCardPagesSmall>
      ) : intentos < limiteIntentos ? (
        <ModalCardMedPages>
          <FormTwoFactor
            setCodigo={setCodigo}
            reenviarCodigo={reenviarCodigo}
            cancelar={cancelar}
            enviarCodigo={enviarCodigo}
            activar={activar}
            userName={userName}
            codeValid={codInvalido}
          />
          {/* <Help /> */}
        </ModalCardMedPages>
      ) : (
        <ModalCardMedPages typeModal={true}>
          <div
            className="overflow-x-hidden mx-auto"
            style={{
              // minWidth: "600px",
              // minHeight: "560px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="row m-0"
              style={{
                width: "60%",
                height: "60%",
                maxWidth: "279px",
              }}
            >
              <div className="d-flex flex-column h-100 justify-content-around">
                <div className="card_title">
                  <img
                    src={logo}
                    style={{
                      ...(dimensionesLogo?.w
                        ? { width: dimensionesLogo?.w }
                        : { width: "100%" }),
                      ...(dimensionesLogo?.h
                        ? { height: dimensionesLogo?.h }
                        : {}),
                    }}
                    alt=""
                  />
                </div>
                <p className="card_text">
                  Haz excedido el número de intentos máximos de ingreso para el
                  código. Espera <bold className="fw-bold">15 minutos</bold>{" "}
                  para realizar un nuevo intento
                </p>
                <div className="container-fluid p-0 d-flex gap-1 justify-content-between">
                  <button
                    type="button"
                    className="button button_active"
                    style={{ height: "fit-content" }}
                    onClick={cancelar}
                    data-testid="Cancelar"
                  >
                    Regresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalCardMedPages>
      )}
      {error && <AlertaGeneral type={"error"}>{error}</AlertaGeneral>}
      {alertsuccess && !error && (
        <AlertaGeneral type={"success"}>{alertsuccess}</AlertaGeneral>
      )}
      {loading && <Loader />}
    </>
  );
};

export default TwoFactor;
