import React, { useState, useEffect } from "react";
import Link from "./Link";
import { useNavigate } from "react-router-dom";
import { Permisos } from "../../services/permisos.js";
import IconCirculoCredito from "./IconCirculoCredito.jsx";
import ModalCerrarSesion from "./ModalCerrarSesion.jsx";
import { loadEndpoints, endpoints } from "../../services/api.js";
import { ReactComponent as Salir } from "../../assets/icons/Salir.svg";

export default function NavBarDesktop() {
  const navigate = useNavigate();
  const [modalCerrar, setmodalCerrar] = useState(false);
  const [logo, setLogo] = useState("/images/dicio/logo_white.webp");
  const [colorIconSalir, setColorIconSalir] = useState("white");
  const cargarVariables = async () => {
    await loadEndpoints();
    if (endpoints?.view_config?.images?.logo_contraste)
      setLogo(endpoints?.view_config?.images?.logo_contraste);
  };
  const cambiarColor = async ({ over = false }) => {
    await loadEndpoints();
    if (endpoints?.view_config?.colors?.text_primary) {
      if (over) setColorIconSalir(endpoints?.view_config?.colors?.text_primary);
      else setColorIconSalir("white");
    }
  };
  useEffect(() => {
    cargarVariables();
  }, []);

  const cerrarSesion = () => {
    setmodalCerrar(true);
  };
  const cancelarModal = () => {
    setmodalCerrar(false);
  };
  return (
    <div
      className={` desktop_nav px-0 flex-column align-items-center h-md-100 justify-content-between dco_nav`}
    >
      <IconCirculoCredito />
      <div className="dco_nav_logo_container">
        {
          //<MenuButton handleActive={handleActive} />
        }
        <img src={logo} alt="Nav bar logo" className={"dco_nav_logo"} />
        <div className="logo_d"></div>
        <div className={`bordered my-4 dco_nav_bar`}></div>
      </div>
      <div className={"dco_nav_responsive_container"}>
        <div className={"dco_nav_links_container"}>
          {Permisos.verificarPermiso("Dashboard", "Ver la sección") && (
            <Link path="dashboard">Dashboard</Link>
          )}
          {Permisos.verificarPermiso("Transacciones", "Ver la sección") && (
            <Link path="transacciones">Transacciones</Link>
          )}
          {Permisos.verificarPermiso("Nodos", "Ver la sección") && (
            <Link path="nodos">Nodos</Link>
          )}
          {Permisos.verificarPermiso("Usuario", "Ver la sección") && (
            <Link path="usuarios">Usuarios</Link>
          )}
          {Permisos.verificarPermiso("Cargas BD", "Ver la sección") && (
            <Link path="gestion">Cargas BD</Link>
          )}
          {/*Permisos.verificarPermiso("Bitácora", "Ver la sección") && (
            <Link path="actividad">Bitácora</Link>
          )*/}
        </div>
        <div className={"dco_nav_links_container mt-5"}>
          <Link path="ajustes">Ajustes</Link>
          {/*<a
              href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
              target="_blank"
              className={"dco_nav_link"}
              path="ayuda"
            >
              <span
                style={{ backgroundImage: "url('/images/nav/help.webp')" }}
              />
              Ayuda
            </a>*/}
          <a
            href="#"
            className={"dco_nav_link"}
            onClick={cerrarSesion}
            onPointerOver={() => {
              cambiarColor({ over: true });
            }}
            onPointerLeave={() => {
              cambiarColor({ over: false });
            }}
          >
            <span>
              <Salir stroke={colorIconSalir} />
            </span>
            Salir
          </a>
          {
            //<Logout />
          }
        </div>
      </div>
      {modalCerrar && <ModalCerrarSesion cancelarModal={cancelarModal} />}
    </div>
  );
}
