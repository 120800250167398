import React, { useRef } from "react";
import Modal from "../Modal/Modal";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import RolForm from "./RolForm";

export default function CreateRol() {
  const modalRef = useRef(null);
  const handleModal = () => {
    modalRef?.current?.handleOpenModal();
  };
  return (
    <>
      <Modal ref={modalRef}>
        <article
          className={"bg-white rounded rol_modal_card mx-auto modal_create_rol"}
        >
          <div
            style={{ maxWidth: "576px", gap: "25px" }}
            className={
              "container-fluid p-0 d-flex flex-column align-items-start"
            }
          >
            <h4 className={"card_title m-0"}>Creación de roles</h4>
            <p style={{ margin: "0 0 3px 0" }} className={"card_text"}>
              Crea un nuevo rol en base a las funciones que tendrá cada uno de
              ellos
            </p>
            <RolForm handleModal={handleModal} />
          </div>
        </article>
      </Modal>
      <button
        data-testid="crearRol-button"
        type="button"
        className={`d-flex flex-nowrap text-nowrap gap-1 create_button`}
        onClick={handleModal}
      >
        Crear roles
      </button>
    </>
  );
}
