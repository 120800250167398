import React, {
  useRef,
  useState,
  useReducer,
  useMemo,
  createContext,
  useEffect,
  useCallback,
} from "react";
import Table from "../Table/Container";
import Loader from "../Loader/Loader";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import ExportData from "./ExportData";
import FilterForm from "./FilterForm";
import { Permisos } from "../../services/permisos";
import Validation from "./Validation";
import DetallesEstatusNodo from "./DetallesEstatusNodo";
import {
  getNodeCatalog,
  getDataNodes,
  downloadNodeInfo,
} from "../../services/api";
import {
  formatToTwoDecimals,
  handleGetCatalogs,
  TIME_ALERT,
  validateEmptyValues,
} from "../../services/data";
import usePaginationBack from "../Table/usePaginationBack";
import Pagination from "../Table/Pagination";
import LimpiarFiltros from "../Sharing/LimpiarFiltros";

export const NodeContext = createContext();

const INITIAL_VALUES = {
  search: "",
  node: "",
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export default function NodosComponent() {
  const [values, dispatch] = useReducer(formReducer, INITIAL_VALUES);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [nodeCatalog, setNodeCatalog] = useState([]);
  const dataTable = useMemo(() => tableData, [tableData]);
  const abortControllerRef = useRef(null);
  const signalErrorController = useRef(false);
  const [orderConfig, setOrderConfig] = useState({
    key: "nodo_id",
    direction: "asc",
  });

  const {
    handleSelectedPage,
    handleNextPage,
    handlePreviousPage,
    handleResetPagination,
    handleValidateTotalPages,
    pagination,
  } = usePaginationBack();

  const handleLoading = () => {
    setLoading((prevState) => !prevState);
  };

  const handleError = (errorValue) => {
    //setError((prevState) => !prevState);
    setError(errorValue);
    signalErrorController.current = errorValue;
  };

  const handleErrorMessage = useCallback((msj, errorValue) => {
    setErrorMessage(msj);
    handleError(errorValue);
  }, []);

  const handleSuccess = () => {
    setSuccess((prevState) => !prevState);
  };

  const handleSuccessMessage = useCallback((msj) => {
    setSuccessMessage(msj);
  }, []);

  const setFieldValue = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleChange = (e) => {
    handleResetPagination();
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSelectChange = (values) => {
    handleResetPagination();
    const { name, value } = values;
    setFieldValue(name, value);
  };

  const handleExport = async () => {
    const data = dataTable;
    return data;
  };

  const handleGetNodeInfo = useCallback(async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      setLoading(true);
      const noEmptyValues = validateEmptyValues(values);
      const response = await getDataNodes({
        page: pagination.page,
        ...noEmptyValues,
        signal: abortControllerRef.current.signal,
        OrderAscending: orderConfig.direction,
        OrderBy: orderConfig.key,
        ...(noEmptyValues.node === "Todos los nodos"
          ? { node: "" }
          : { node: noEmptyValues.node }),
      });
      console.log(response)
      if (!response.success) {
        if (response.name === "AbortError") {
          setTableData([]);
          console.log("Request canceled");
          return;
        }
        setLoading(false);
        if (!signalErrorController?.current) {
          handleErrorMessage(response.message, true);
          setTimeout(() => {
            handleErrorMessage("", false);
          }, TIME_ALERT);
        }
        return;
      }
      setLoading(false);
      setTableData(response?.data?.list ?? []);
      handleValidateTotalPages({
        totalPages: response?.data?.totalPages,
        page: response?.data?.page,
        totalElements: response?.data?.totalElements,
      });
      return;
    } catch (error) {
      console.log("Error en el handler");
      console.error(error);
      if (error.name === "AbortError") {
        console.log("Request canceled");
        return;
      }
      setLoading(false);
      setTableData([]);
      handleErrorMessage("Lo sentimos ocurrió un error", true);
      setTimeout(() => {
        handleErrorMessage("", false);
      }, TIME_ALERT);
      return;
    }
  }, [
    handleErrorMessage,
    handleValidateTotalPages,
    pagination.page,
    values,
    orderConfig,
  ]);

  const availableDownloads = useMemo(
    () => ({
      excel: {
        params: "formato=xls",
        name: "Nodos.xls",
      },
      csv: {
        params: "formato=csv",
        name: "Nodos.csv",
      },
      pdf: {
        params: "formato=pdf",
        name: "Nodos.pdf",
      },
    }),
    []
  );

  const handleDownloadData = useCallback(
    async (selected, handleModal) => {
      try {
        setLoading(true);
        const response = await downloadNodeInfo({
          ...values,
          download: availableDownloads[selected],
          OrderAscending: orderConfig.direction,
          OrderBy: orderConfig.key,
          ...(values.node === "Todos los nodos"
            ? { node: "" }
            : { node: values.node }),
        });
        if (!response.success) {
          setLoading(false);
          handleErrorMessage(response.message, true);
          setTimeout(() => {
            handleErrorMessage("", false);
          }, TIME_ALERT);
          return;
        }
        setLoading(false);
        handleSuccess();
        handleSuccessMessage(
          "La exportación de datos se ha realizado con éxito"
        );
        setTimeout(() => {
          handleSuccess();
          handleModal();
          handleSuccessMessage("");
        }, TIME_ALERT);
      } catch (error) {
        console.log(error);
        setLoading(false);
        handleErrorMessage("Lo sentimos ocurrió un error", true);
        setTimeout(() => {
          handleErrorMessage("", false);
        }, TIME_ALERT);
        return;
      }
    },
    [
      availableDownloads,
      handleErrorMessage,
      handleSuccessMessage,
      values,
      orderConfig,
    ]
  );

  useEffect(() => {
    handleGetCatalogs({
      service: getNodeCatalog,
      setter: setNodeCatalog,
      catalog: "nodes",
    });
  }, []);

  useEffect(() => {
    handleGetNodeInfo({});
  }, [handleGetNodeInfo, pagination.page, values, orderConfig]);

  const handleOrderByColumns = useCallback(({ sort_key, order }) => {
    if (!navigator.onLine) {
      if (!signalErrorController?.current) {
        setError(true);
        setErrorMessage(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
      }
      return;
    }
    setOrderConfig({ order, orderBy: sort_key });
  }, []);

  const columns = useMemo(
    () => [
      {
        name: "Nodo",
        selector: (row) =>
          nodeCatalog.find((obj) => obj.nodo_id === row.nodo_id)?.nodo_name ||
          "",
        key_name: "nodo_info.nodo_name",
        service: handleOrderByColumns,
      },
      {
        name: "Registros en el nodo",
        selector: (row) => row.registros_enrolados_total,
        key_name: "registros_enrolados_total",
        service: handleOrderByColumns,
      },
      {
        name: " Transacciones en el nodo",
        selector: (row) => row.total_transacciones,
        key_name: "total_transacciones",
        service: handleOrderByColumns,
      },
      {
        name: "Coincidencia",
        selector: (row) => row.coincidencia ?? "",
        key_name: "coincidencia",
      },
      {
        name: "Sin coincidencia",
        selector: (row) => row.sin_coincidencia ?? "",
        key_name: "sin_coincidencia",
      },
      {
        name: "No existentes",
        selector: (row) => row.no_existentes ?? "",
        key_name: "no_existentes",
      },
      {
        name: "Cargas en el nodo",
        selector: (row) => row.total_cargas,
        key_name: "total_cargas",
        service: handleOrderByColumns,
      },
      {
        name: "Índice calidad del nodo",
        selector: (row) => row?.nodo_data_quality_score ? formatToTwoDecimals(row?.nodo_data_quality_score, 1) : 0.0,
        key_name: "nodo_data_quality_score",
        service: handleOrderByColumns,
      },
      {
        name: "Estatus del nodo",
        selector: (row) => <Validation validation={row.status === "activo"} />,
        key_name: "status",
        service: handleOrderByColumns,
      },

      {
        name: "Acciones",
        selector: (row) => <DetallesEstatusNodo data={row} />,
      },
    ],
    [handleOrderByColumns, nodeCatalog]
  );

  // Constants
  const memoizedUserTable = useMemo(() => {
    return (
      <Table
        columns={columns}
        data={dataTable}
        header_classname="table_header"
        sortConfig={orderConfig}
        setSortConfig={setOrderConfig}
        header_styles={{
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
        }}
      >
        <Pagination
          {...{
            pagination,
            handlePreviousPage,
            handleSelectedPage,
            handleNextPage,
          }}
        />
      </Table>
    );
  }, [
    columns,
    dataTable,
    handleNextPage,
    handlePreviousPage,
    handleSelectedPage,
    orderConfig,
    pagination,
  ]);

  return (
    <NodeContext.Provider
      value={{
        handleExport,
        nodeCatalog,
        handleDownloadData,
        setError,
        setErrorMessage,
        error,
      }}
    >
      <div className="container-fluid d-flex flex-column p-0 dco_nodos_table_container">
        <div
          style={{ marginBottom: "11px" }}
          className="d-flex flex-wrap-reverse flex-lg-nowrap justify-content-end mt-3"
        >
          {Permisos.verificarPermiso("Nodos", "Buscar") && (
            <FilterForm
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              values={values}
            />
          )}
          <div className="d-flex gap-2 align-items-end flex-wrap flex-sm-nowrap">
            <LimpiarFiltros
              handleChange={handleSelectChange}
              INITIAL_VALUES={INITIAL_VALUES}
              modulo="Nodos"
            />
            {Permisos.verificarPermiso("Nodos", "Exportar") && <ExportData />}
          </div>
        </div>
        {memoizedUserTable}
      </div>
      {loading && <Loader />}
      {success && (
        <AlertaGeneral type={"success"}>{successMessage}</AlertaGeneral>
      )}
      {error && <AlertaGeneral type={"error"}>{errorMessage}</AlertaGeneral>}
    </NodeContext.Provider>
  );
}
