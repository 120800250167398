import React, { useEffect, useState } from "react";
import InputNewPassword from "../Inputs/InputNewPassword";
import { validateEmailLogin, resetPassword } from "../../services/api";
import { useNavigate } from "react-router-dom";
import AlertaGeneral from "../Alerts/AlertaGeneral";
import Loader from "../Loader/Loader";
import { TIME_ALERT } from "../../services/data";

const valid_field = ["strong", "green"];

const validateField = (data) => {
  return valid_field.reduce((acc, val) => acc && val !== data);
};

const FormNewPasswordRecuperar = () => {
  const [queryParams, setQueryParams] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [password, setPassword] = useState("");
  const [bandera, setBandera] = useState("blank");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await resetPassword({
        ...queryParams,
        newPassword: password,
      });
      if (!response.success) {
        setLoading(false);
        setError(true);
        setErrorMessage(response.message);
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        console.error("Error request");
        return;
      }
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(response.message);
      setFinish(true);
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
      }, TIME_ALERT);
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrorMessage("Lo sentimos ocurrió un error, intente más tarde");
      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, TIME_ALERT);
      console.error("Error request");
      console.error(error);
      return;
    }
  };

  const handleGoLogin = () => {
    navigate("/login");
  };

  const getUserData = async (correo) => {
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_CONNECTION");
      }
      setLoading(true);
      const response = await validateEmailLogin(correo);
      if (!response.success) {
        setLoading(false);
        setError(true);
        setErrorMessage(response.error_message);
        setTimeout(() => {
          setError(false);
          setErrorMessage("");
        }, TIME_ALERT);
        return;
      }
      setLoading(false);
      setUserData(response.data);
    } catch (error) {
      if (error.message === "NETWORK_CONNECTION") {
        setError(
          "No hay conexión a Internet. Por favor, verifica tu conexión."
        );
        return;
      }
      setLoading(false);
      console.log(error);
      setError("Lo sentimos ocurrió un error");
    }
  };

  useEffect(() => {
    const search = window.location.search.replaceAll("+", "%2B");
    const params = new URLSearchParams(search);
    const token = params.get("Token");
    const email = params.get("Email");
    setQueryParams({
      token,
      email,
    });
    getUserData(email);
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={` p-0 overflow-hidden`}
        style={{ maxWidth: "279px" }}
      >
        <div style={{ gap: "24px" }} className="col p-0">
          <p
            className="fw-bold"
            style={{ marginTop: "69px", marginBottom: "0px" }}
          >
            Hola {userData?.firstName}, captura tu nueva contraseña
          </p>
          <div
            style={{
              opacity: `${finish ? "0.6" : "1"}`,
              pointerEvents: `${finish ? "none" : ""}`,
              marginTop: "42px",
            }}
            className="container-fluid p-0 marginTop-0-container"
          >
            <label></label>
            <InputNewPassword
              setBanderaProp={setBandera}
              setPasswordProp={setPassword}
            />
          </div>
          {!finish && (
            <div style={{ marginTop: "33px" }} className="d-flex gap-2">
              <button
                className={`button ${
                  bandera === "strong" || bandera === "green"
                    ? "button_active"
                    : "button_disabled"
                }`}
                disabled={!(bandera === "strong" || bandera === "green")}
                style={{ maxWidth: "fit-content" }}
                type="submit"
                data-testid="aceptar-button"
              >
                Aceptar
              </button>
            </div>
          )}
          {finish && (
            <div style={{ marginTop: "33px" }} className="d-flex gap-2">
              <button
                className={`button button_active`}
                style={{ maxWidth: "fit-content" }}
                onClick={handleGoLogin}
                type="button"
                data-testid="volver-button"
              >
                Volver a iniciar sesión
              </button>
            </div>
          )}
        </div>
      </form>
      {error && <AlertaGeneral type="error">{errorMessage}</AlertaGeneral>}

      {success && (
        <AlertaGeneral type="success">{successMessage}</AlertaGeneral>
      )}

      {loading && <Loader />}
    </>
  );
};

export default FormNewPasswordRecuperar;
