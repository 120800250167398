import React, { Fragment, useEffect, useRef, useState } from "react";
import TituloPrincipal from "../../Dashboard/TituloPrincipal";
import c3 from "c3";
import { Row } from "react-bootstrap";
import { index } from "d3";
import * as d3 from "d3";
import useColors from "../useColors";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

const colorsPaterns = ["#5E3FBE", "#E5DAFB", "#F4F0FD"];

const TransactionsByNodeChart = ({
  titulo = "Transacciones por nodo: ",
  minHeight,
  widthGeneral,
  heightGeneral,
  size,
  data = {},
  nodesCatalog,
  flexFlow,
  setRef,
  refKey,
  getIdGraphic,
}) => {
  const chartRef = useRef(null);
  const windowWidth = useWindowWidth();
  const uniqueId = `chart_transactions_consorcio`;
  const [nodesData, setNodesData] = useState([]);
  const { opacityOptions } = useColors();

  useEffect(() => {
    if (chartRef.current && opacityOptions) {
      let valuesData = [];
      setNodesData(Object.entries(data));
      if (Object.entries(data).length > 0) {
        Object.entries(data).forEach((element) => {
          valuesData.push([element[0] ?? "", element[1].porcentaje]);
        });
      } else {
        valuesData = [
          ["Banco Azteca", 0],
          ["BanCoppel", 0],
          ["Afirme", 0],
        ];
      }

      const chart = c3.generate({
        bindto: `#${uniqueId}`,
        data: {
          columns: valuesData,
          type: "pie",
        },
        color: {
          pattern: opacityOptions,
        },
        size: size,
        tooltip: {
          format: {
            name: function (name, ratio, id, index) {
              return nodesCatalog?.find
                ? nodesCatalog?.find((node) => node.nodo_id === name)
                    ?.nodo_name ?? `Nodo ${index}`
                : `Nodo ${index}`;
            },
          },
        },
        legend: {
          show: false,
          position: "inset",
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data, opacityOptions]);

  useEffect(() => {
    if (setRef && refKey) {
      setRef(refKey, chartRef.current);
    }
  }, [setRef, refKey]);

  return (
    <div
      className={"dashboard_cards dashboard_chart_cards"}
      style={{
        width: "100%",
        height: heightGeneral,
        ...(windowWidth >= 1280
          ? widthGeneral
            ? { width: widthGeneral }
            : {}
          : flexFlow
          ? { flex: flexFlow }
          : {}),
        ...(minHeight ? { minHeight: minHeight } : {}),
      }}
    >
      <div className="d-flex flex-row mb-3">
        <TituloPrincipal titulo={titulo} number={""} />
      </div>
      <div className="d-flex" ref={chartRef}>
        <div
          className="container_legend_pie"
          style={{ flex: 1.5, marginTop: 20 }}
        >
          <div className="list_legend_pie">
            {nodesData.length > 0 && nodesCatalog.length > 0 ? (
              nodesData.map((element, index) => (
                <div className="text_legend_pie" key={index}>
                  <div
                    className="baz_point"
                    style={{ backgroundColor: opacityOptions[index] }}
                  ></div>
                  {nodesCatalog?.find
                    ? nodesCatalog?.find((node) => node.nodo_id === element[0])
                        ?.nodo_name ?? `Nodo ${index}`
                    : `Nodo ${index}`}{" "}
                  <span>/ {element[1]?.peticiones ?? "0"}</span>
                </div>
              ))
            ) : (
              <Fragment>
                {nodesCatalog.length > 0 ? (
                  nodesCatalog.map((element, indexCatalog) => (
                    <div className="text_legend_pie">
                      <div
                        className="baz_point"
                        style={{
                          backgroundColor: opacityOptions[indexCatalog],
                        }}
                      ></div>
                      {element.nodo_name ?? `Nodo ${indexCatalog}`}{" "}
                      <span>/ 0</span>
                    </div>
                  ))
                ) : (
                  <div className="text_legend_pie">
                    <div
                      className="baz_point"
                      style={{ backgroundColor: opacityOptions[index] }}
                    ></div>
                    Sin registros
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div id={uniqueId} style={{ flex: 2 }}></div>
      </div>
    </div>
  );
};

export default TransactionsByNodeChart;
