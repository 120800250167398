import React, { useEffect, useState } from "react";
import { loadEndpoints, endpoints } from "../../services/api";

export default function useColors() {
  const [opacityOptions, setOpacityOptions] = useState([
    "#6236FF",
    "#6236FFB3",
    "#6236FF99",
    "#6236FF80",
    "#6236FF66",
    "#6236FF59",
    "#6236FF4D",
    "#6236FF40",
    "#6236FF33",
    "#6236FF1A",
    "#f0ebff",
  ]);
  useEffect(() => {
    const cargarVariables = async () => {
      await loadEndpoints();
      if (endpoints?.view_config?.colors?.paletaGraficas)
        setOpacityOptions(endpoints?.view_config?.colors?.paletaGraficas);
    };
    cargarVariables();
  }, []);

  return {
    opacityOptions,
  };
}
