import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadEndpoints, endpoints } from "../../services/api";
import { ReactComponent as Dashboard } from "../../assets/icons/Dashboard.svg";
import { ReactComponent as Transacciones } from "../../assets/icons/Transacciones.svg";
import { ReactComponent as Nodos } from "../../assets/icons/Nodos.svg";
import { ReactComponent as DB } from "../../assets/icons/DB.svg";
import { ReactComponent as Ajustes } from "../../assets/icons/Ajustes.svg";
import { ReactComponent as Usuarios } from "../../assets/icons/Usuarios.svg";

export default function Link({ path, children }) {
  const [colorFill, setColorFill] = useState("white");
  const cargarvariables = async ({ over = false }) => {
    await loadEndpoints();
    if (
      (window.location.pathname.replaceAll("/", "") === path || over) &&
      endpoints?.view_config?.colors?.text_primary
    ) {
      setColorFill(endpoints?.view_config?.colors?.text_primary);
    } else {
      setColorFill("white");
    }
  };
  useEffect(() => {
    cargarvariables({ over: false });
  }, []);

  const catalogoLogos = {
    dashboard: <Dashboard fill={colorFill} />,
    transacciones: <Transacciones fill={colorFill} />,
    nodos: <Nodos fill={colorFill} />,
    usuarios: <Usuarios fill={colorFill} />,
    gestion: <DB fill={colorFill} />,
    ajustes: <Ajustes stroke={colorFill} />,
  };
  return (
    <NavLink
      to={`/${path}`}
      className={
        "dco_nav_link " +
        (window.location.pathname.replaceAll("/", "") === path &&
          "dco_nav_link_active")
      }
      onPointerOver={() => {
        cargarvariables({ over: true });
      }}
      onPointerLeave={() => {
        cargarvariables({ over: false });
      }}
    >
      <span>{catalogoLogos[path]}</span>
      {children}
    </NavLink>
  );
}
