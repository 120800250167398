import React, { useEffect } from 'react';
import './App.css';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import { createBrowserHistory } from "history";
//importación de Componentes
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import RecuperarPassword from './pages/RecuperarPassword';
import Ajustes from './pages/Ajustes';
import CompletarRegistro from './pages/completarRegistro';
import PrivateRoutes from './components/Auth/PrivateRoutes';
import Users from "./pages/Users";
import PasswordRecovery from "./pages/PasswordRecovery";
import RestorePassword from './pages/RestorePassword';
import BloqueoUsuario from './pages/BloqueoUsuario';
import Management from './pages/Management';
import Transactions from './pages/Transactions';
import Actividad from './pages/Actividad';
import Nodos from './pages/Nodos';
import { loadEndpoints, endpoints } from './services/api';

const history = createBrowserHistory();
const cargarVariablesColores=async ()=>{
  await loadEndpoints();
  const root = document.documentElement;
  if(endpoints?.view_config){
    const view_config= endpoints?.view_config;
    view_config.colors?.primary_color&&root.style.setProperty('--primary-color', view_config.colors?.primary_color);
    view_config.colors?.primary_color&&root.style.setProperty('--bg-nav-link-active', `linear-gradient(180deg, ${view_config.colors?.primary_color} 0%, ${view_config.colors?.primary_color} 100%)`);
    view_config.colors?.primary_color&&root.style.setProperty('--text_primary-color', view_config.colors?.text_primary);
    view_config.colors?.secondary_color&&root.style.setProperty('--secondary-color', view_config.colors?.secondary_color);
    view_config.colors?.text_secondary&&root.style.setProperty('--text-secondary-color', view_config.colors?.text_secondary);
    view_config.colors?.accent_color&&root.style.setProperty('--accent-color', view_config.colors?.accent_color);
    view_config.colors?.text_accent&&root.style.setProperty('--text_accent-color', view_config.colors?.text_accent);
    view_config.colors?.fondo_color&&root.style.setProperty('--background-color', view_config.colors?.fondo_color);
    view_config.flags?.ocultar_icon_cdc_superior&&root.style.setProperty('--icon_cdc_superior', "hidden");
  }
}
function App() {
  useEffect(() => {
    cargarVariablesColores()
  }, [])
  
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={< Login/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/recuperar-contrasena" element={<PasswordRecovery/>}></Route>
        <Route path="/recuperar-password" element={<RecuperarPassword/>}></Route>
        <Route path="/register-callback" element={<CompletarRegistro/>}></Route>
        <Route path="/restaurar-contrasena" element={<RestorePassword/>}></Route>
        <Route path="/bloqueo-usuario" element={<BloqueoUsuario/>}></Route>
        <Route element={<Login />} path="/change-password-expired"></Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/transacciones" element={<Transactions />}></Route>
          <Route path="/nodos" element={<Nodos />}></Route>
          <Route path="/gestion" element={<Management />}></Route>
          {/*<Route path="/actividad" element={<Actividad />}></Route>*/}
          <Route path="/ajustes" element={<Ajustes />}></Route>
          <Route element={<Users />} path="/usuarios"></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;