import React, { useState, useEffect } from "react";
import ModalCardPagesSmall from "../Modal/ModalCardPagesSmall.jsx";
import { useNavigate } from "react-router-dom";
import { Permisos } from "../../services/permisos.js";
const ModalCerrarSesion = ({ cancelarModal }) => {
  const navigate = useNavigate();

  const cerrarSesion = () => {
    sessionStorage.clear();
    Permisos.destroy();
    navigate("/login");
  };

  return (
    <>
      <ModalCardPagesSmall>
        <div className="overflow-x-hidden contenido-modal-cerrar-sesion">
          <div className="row m-0 mb-4 container-cerrar-sesion">
            <div className="d-flex flex-column h-100 justify-content-between p-0">
              <h4 className="card_title" style={{ marginBottom: "10px" }}>
                Cerrar sesión
              </h4>
              <p className="card_text">
                En este momento cerrarás tu sesión, perderás cualquier
                información que no hayas guardado ¿Estás seguro de que deseas
                cerrar la sesión?
              </p>
              <div className="container-fluid p-0 d-flex gap-1">
                <button
                  type="submit"
                  className="button button_active"
                  style={{ height: "fit-content" }}
                  onClick={cerrarSesion}
                  data-testid="close-session-button"
                >
                  Estoy seguro, cerrar sesión
                </button>
                <button
                  type="button"
                  className="cancel_button"
                  style={{ height: "fit-content" }}
                  onClick={cancelarModal}
                  data-testid="cacnel-button"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalCardPagesSmall>
    </>
  );
};

export default ModalCerrarSesion;
