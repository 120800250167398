import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow_down.svg";
import { endpoints, loadEndpoints } from "../../services/api";

export default function Header({
  columns,
  header_styles,
  header_classname,
  handleSort,
  sortConfig,
}) {
  const [colorFill, setColorFill] = useState("white");
  const cargarvariables = async ({ over = false }) => {
    await loadEndpoints();
    if (endpoints?.view_config?.colors?.text_primary) {
      setColorFill(endpoints?.view_config?.colors?.text_primary);
    }
  };
  useEffect(() => {
    cargarvariables({ over: false });
  }, []);
  const handleColumnClick = useCallback(
    async ({ key_name, service, order }) => {
      if (key_name) {
        if (service) await service({ sort_key: key_name, order });
        handleSort(key_name);
      }
    },
    [handleSort]
  );

  return (
    <thead data-testSortDirection={sortConfig.direction ?? ""}>
      <tr
        {...(header_classname
          ? { className: header_classname }
          : { className: "table_header" })}
        {...(header_styles && { style: header_styles })}
      >
        {columns.map(({ name, style, className, ...props }, index) => (
          <th
            onClick={() =>
              props.key_name &&
              handleColumnClick({
                key_name: props.key_name,
                service: props.service,
                order: sortConfig.direction,
              })
            }
            data-testid={props?.key_name ?? ""}
            scope="col"
            key={`table-header-cell-${index}`}
            className={`
              ${
                sortConfig?.key === props.key_name
                  ? "dco_column_sort_selected"
                  : ""
              }
              ${
                props.key_name
                  ? sortConfig.key === props.key_name
                    ? sortConfig.direction === "asc"
                      ? "sortable_column_asc"
                      : "sortable_column_desc"
                    : "sortable_column_desc"
                  : ""
              }
              ${className ? `${className}` : "table_header_cell"}
              `}
            {...(style && { style })}
          >
            {name}
            <span>
              <Arrow fill={colorFill} />
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
}
