import React, { useEffect, useState } from "react";

export default function Validacion({ value }) {
  const [color, setColor] = useState();
  const [colorText, setColorText] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    if (value) {
      if (value === "N/A") {
        setColor("#FFFFFF");
        setText("No iniciada");
        setColorText("#B3AFAF");
      } else if (value === "No existe") {
        setColor("#7F7F7FCC");
        setText("Sin registro");
        setColorText("#FFFFFF");
      } else if (value === "Coincidencia") {
        setColor("#609571");
        setText("Exitosa");
        setColorText("#FFFFFF");
      } else if (value === "Sin Coincidencia") {
        setColor("#FFBB00");
        setText("No exitosa");
        setColorText("#FFFFFF");
      } else if (value === "No iniciada") {
        setColor("#FFFFFF");
        setText("No iniciada");
        setColorText("#B3AFAF");
      } else if (value === "Sin registro") {
        setColor("#7F7F7FCC");
        setText("Sin registro");
        setColorText("#FFFFFF");
      } else if (value === "Exitosa") {
        setColor("#609571");
        setText("Exitosa");
        setColorText("#FFFFFF");
      } else if (value === "No exitosa") {
        setColor("#FFBB00");
        setText("No exitosa");
        setColorText("#FFFFFF");
      }
    }
  }, [value]);

  return (
    <div
      className="label_validacion_transacciones"
      style={{
        color: colorText,
        ...{ backgroundColor: color },
        borderColor: colorText,
      }}
    >
      {text}
    </div>
  );
}
