import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import Link from "./Link";
import { Permisos } from "../../services/permisos.js";
import ModalCerrarSesion from "./ModalCerrarSesion.jsx";
import { loadEndpoints, endpoints } from "../../services/api.js";
import { ReactComponent as Salir } from "../../assets/icons/Salir.svg";

export default function NavBarMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [modalCerrar, setmodalCerrar] = useState(false);
  const [logo, setLogo] = useState("/images/dicio/logo_white.webp");
  const [colorIconSalir, setColorIconSalir] = useState("white");

  useEffect(() => {
    const cargarVariables = async () => {
      await loadEndpoints();
      if (endpoints?.view_config?.images?.logo_mobile)
        setLogo(endpoints?.view_config?.images?.logo_mobile);
      else if (endpoints?.view_config?.images?.logo_contraste)
        setLogo(endpoints?.view_config?.images?.logo_contraste);
    };
    cargarVariables();
  }, []);
  const cambiarColor = async ({ over = false }) => {
    await loadEndpoints();
    if (endpoints?.view_config?.colors?.text_primary) {
      if (over) setColorIconSalir(endpoints?.view_config?.colors?.text_primary);
      else setColorIconSalir("white");
    }
  };

  const cerrarSesion = () => {
    setmodalCerrar(true);
    // sessionStorage.clear();
    // Permisos.destroy();
    // navigate("/login");
  };
  const handleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const cancelarModal = () => {
    setmodalCerrar(false);
  };

  return createPortal(
    <div className="container-fluid mobile_nav">
      <label className={"hamburger " /*+ (isOpen && "menu_mobile_show")*/}>
        <input type="checkbox" onClick={handleMenu} />
        <svg viewBox="0 0 32 32">
          <path
            className="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path className="line" d="M7 16 27 16"></path>
        </svg>
      </label>
      <img src={logo} alt="Nav bar logo" className={"responsive_nav_logo"} />
      <div
        className={`menu_mobile_container z-3 d-flex flex-column justify-content-between ${
          isOpen ? "menu_mobile_container_show" : "menu_mobile_container_hidde"
        }`}
      >
        <div className={"dco_nav_links_container"}>
          {Permisos.verificarPermiso("Dashboard", "Ver la sección") && (
            <Link path="dashboard">Dashboard</Link>
          )}
          {Permisos.verificarPermiso("Transacciones", "Ver la sección") && (
            <Link path="transacciones">Transacciones</Link>
          )}
          {Permisos.verificarPermiso("Nodos", "Ver la sección") && (
            <Link path="nodos">Nodos</Link>
          )}
          {Permisos.verificarPermiso("Usuario", "Ver la sección") && (
            <Link path="usuarios">Usuarios</Link>
          )}
          {Permisos.verificarPermiso("Cargas BD", "Ver la sección") && (
            <Link path="gestion">Cargas BD</Link>
          )}
          {/*Permisos.verificarPermiso("Bitácora", "Ver la sección") && (
            <Link path="actividad">Bitácora</Link>
          )*/}
        </div>
        <div className={"dco_nav_links_container mt-5"}>
          <Link path="ajustes">Ajustes</Link>
          {/*
          <a
            href="https://wa.me/5579211062?text=Hola,%20necesito%20ayuda"
            target="_blank"
            className={"dco_nav_link"}
            path="ayuda"
          >
            <span style={{ backgroundImage: "url('/images/nav/help.webp')" }} />
            Ayuda
          </a>*/}
          <a
            href="#"
            className={"dco_nav_link"}
            onClick={cerrarSesion}
            onPointerOver={() => {
              cambiarColor({ over: true });
            }}
            onPointerLeave={() => {
              cambiarColor({ over: false });
            }}
          >
            <span>
              <Salir stroke={colorIconSalir} />
            </span>
            Salir
          </a>
        </div>
      </div>
      {modalCerrar && <ModalCerrarSesion cancelarModal={cancelarModal} />}
    </div>,
    document.getElementsByTagName("body")[0]
  );
}
